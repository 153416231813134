// import React, { useState } from 'react';
// import './Destination.css';
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import Button from '@mui/material/Button';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
// import logo from '../../Assets/Images/logo.png';

// function KeyFeature({ title, description }) {
//   const [isHovered, setIsHovered] = useState(false);

//   return (
//     <div
//       className={`key-feature ${isHovered ? 'hovered' : ''}`}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       <div className="key-feature-title">{title}</div>
//       {isHovered && (
//         <Typography variant="body2" color="text.secondary">
//           {description}
//         </Typography>
//       )}
//     </div>
//   );
// }

// export default function Destination() {
//   const features = [
//     {
//       title: 'Barcode Scanning',
//       description:
//         'Scan supplement barcodes to retrieve information from the Dietary Supplement Label Database (DSLD).',
//     },
//     {
//       title: 'Dosage Control',
//       description: 'Easily adjust and monitor the dosage of your supplements.',
//     },
//     {
//       title: 'Print Supplements',
//       description:
//         'Export your list of supplements and their component breakdowns for easy reference.',
//     },
//     {
//       title: 'Visualization',
//       description:
//         'Visualize your supplement intake compared to recommended daily allowances (RDA).',
//     },
//     {
//       title: 'Help Desk Support',
//       description: 'Get assistance with any questions or concerns through our help desk system.',
//     },
//   ];

//   return (
//     <div className="landing-page">
//       <div className="container">
//         <div className="navbar">
//           <h1 id="logo">NutraData</h1>
//           <Link id="mission" underline="none" color="#454955" href="/missionstatement" variant="h5">
//             Mission Statement
//           </Link>
//           <Link id="history" underline="none" color="#454955" href="/vision" variant="h5">
//             Vision
//           </Link>
//           <Button variant="contained" id="login-button" href="/landing">
//             Create Account / Login
//           </Button>
//         </div>
//         <div className="landing-content">
//           <div class="landing-content fade-in">
//             <div className="landing-logo">
//               <Avatar alt="NutraData Logo" src={logo} sx={{ width: 100, height: 100, marginRight: '40px' }} />
//               <h2 id="needs"> Where all your supplement needs are met.</h2>
//             </div>
//           </div>
//           <div className="landing-text">
//             <h3>
//               Welcome to NutraData – the modern and reliable supplement tracking app that makes keeping track of your supplements a breeze! With NutraData, simply scan the barcode of your supplement and let us do the rest. Our inviting platform helps you stay on top of your supplement regimen, so you can achieve your health goals with ease. Say goodbye to the hassle of manual tracking and hello to NutraData – the smarter way to supplement!
//             </h3>
//             <div className="key-features">
//               <h2 id="features-title">Key Features</h2>
//               <div className="key-features-list">
//         {features.map((feature, index) => (
//           <KeyFeature
//             key={index}
//             title={feature.title}
//             description={feature.description}
//           />
//         ))}
//       </div>
//             </div>
//             <div className="landing-tutorial">
//               <br></br>
//               <br></br>
//               <h3>Take control of your supplement routine with NutraData - Watch our tutorial to learn how!</h3>
//               <Button variant="contained" id="tutorial-button" href='https://www.youtube.com/watch?v=08Uk2-tJYwY' target='_blank'>
//                 Tutorial
//               </Button>
//             </div>
//           </div>
//         </div>
//         <div className="social-icons">
//           <IconButton href='https://www.instagram.com/nutradata/' target='_blank'>
//             <InstagramIcon sx={{ fontSize: 30 }} />
//           </IconButton>
//           <IconButton href='https://www.facebook.com/profile.php?id=100092461025402' target='_blank'>
//             <FacebookIcon sx={{ fontSize: 30 }} />
//           </IconButton>
//           <IconButton href='https://twitter.com/NutraData' target='_blank'>
//             <TwitterIcon sx={{ fontSize: 30 }} />
//           </IconButton>
//         </div>
//       </div>
//     </div>
//   );
//}


import React, { useState, useEffect } from 'react';
import './Destination.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import logo from '../../Assets/Images/logo.png';

function KeyFeature({ title, description }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`key-feature ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="key-feature-title">{title}</div>
      {isHovered && (
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      )}
    </div>
  );
}

export default function Destination() {
  const [isPortrait, setIsPortrait] = useState(window.innerWidth < window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const features = [
    {
      title: 'Barcode Scanning',
      description:
        'Scan supplement barcodes to retrieve information from the Dietary Supplement Label Database (DSLD).',
    },
    {
      title: 'Dosage Control',
      description: 'Easily adjust and monitor the dosage of your supplements.',
    },
    {
      title: 'Print Supplements',
      description:
        'Export your list of supplements and their component breakdowns for easy reference.',
    },
    {
      title: 'Visualization',
      description:
        'Visualize your supplement intake compared to recommended daily allowances (RDA).',
    },
    {
      title: 'Help Desk Support',
      description: 'Get assistance with any questions or concerns through our help desk system.',
    },
  ];

  return (
    <div className="landing-page">
      {isPortrait && (
        <div className="rotate-message">
          Please rotate your device to landscape mode for a better experience.
        </div>
      )}
      <div className={`container ${isPortrait ? 'blurred' : ''}`}>
      {(window.innerWidth >= 768) && (
        <div className="navbar">
          <h1 className="logo">NutraData</h1>
          <Link id="mission" underline="none" color="#454955" href="/missionstatement" variant="h5">
            Mission Statement
          </Link>
          <Link id="history" underline="none" color="#454955" href="/vision" variant="h5">
            Vision
          </Link>
          <Button variant="contained" id="login-button" href="/landing">
            Create Account / Login
          </Button>
        </div>
        )}
        {(window.innerWidth <= 768) && (
        <div className="navbarMobile">
          <h1 className="logoMobile">NutraData</h1>
          <Link id="missionMobile" underline="none" color="#454955" href="/missionstatement" variant="h5">
            Mission Statement
          </Link>
          <Link id="historyMobile" underline="none" color="#454955" href="/vision" variant="h5">
            Vision
          </Link>
          <Button variant="contained" size="small" id="login-button" href="/landing">
            Login
          </Button>
        </div>
        )}
        <div className="landing-content">
          <div className="landing-content fade-in">
            <div className="landing-logo">
              <Avatar alt="NutraData Logo" src={logo} sx={{ width: 100, height: 100, marginRight: '40px' }} />
              <h2 id="needs"> Where all your supplement needs are met.</h2>
            </div>
          </div>
          <div className="landing-text">
            {(window.innerWidth >= 768) && (<h3>
              Welcome to NutraData – the modern and reliable supplement tracking app that makes keeping track of your supplements a breeze! With NutraData, simply scan the barcode of your supplement and let us do the rest. Our inviting platform helps you stay on top of your supplement regimen, so you can achieve your health goals with ease. Say goodbye to the hassle of manual tracking and hello to NutraData – the smarter way to supplement!
            </h3>)}
            {(window.innerWidth <= 768) && (<h4>
              Welcome to NutraData – the modern and reliable supplement tracking app that makes keeping track of your supplements a breeze! With NutraData, simply scan the barcode of your supplement and let us do the rest. Our inviting platform helps you stay on top of your supplement regimen, so you can achieve your health goals with ease. Say goodbye to the hassle of manual tracking and hello to NutraData – the smarter way to supplement!
            </h4>)}
            <div className="key-features">
              <h2 id="features-title">Key Features</h2>
              <div className="key-features-list">
                {features.map((feature, index) => (
                  <KeyFeature key={index} title={feature.title} description={feature.description} />
                ))}
              </div>
            </div>
            <div className="landing-tutorial">
              <br />
              <br />
              <h3>Take control of your supplement routine with NutraData - Watch our tutorial to learn how!</h3>
              <Button
                variant="contained"
                id="tutorial-button"
                href="https://www.youtube.com/watch?v=08Uk2-tJYwY"
                target="_blank"
              >
                Tutorial
              </Button>
            </div>
          </div>
        </div>
        <div className="social-icons">
          <IconButton href="https://www.instagram.com/nutradata/" target="_blank">
            <InstagramIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <IconButton href="https://www.facebook.com/profile.php?id=100092461025402" target="_blank">
            <FacebookIcon sx={{ fontSize: 30 }} />
          </IconButton>
          <IconButton href="https://twitter.com/NutraData" target="_blank">
            <TwitterIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
