import React from "react";
import { Redirect } from "react-router-dom";
import { TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import styles from "./Landing.module.css";
import Signup from "./Signup/Signup";

import "@firebase/auth";
import "@firebase/database";
import google from "../../Assets/Images/google.png";
import facebook from "../../Assets/Images/facebook.png";
import logo from "../../Assets/Images/logo.png";
import logo_large from "../../Assets/Images/logo_large.png";
import axios from "axios";
import { Link } from "react-router-dom";

const fields = [
  {
    title: "username",
    label: "Username",
    type: "",
  },
  {
    title: "password",
    label: "Password",
    type: "password",
  },
];

const methods = [
  {
    title: "google",
    label: "Google",
    img: google,
  },
  {
    title: "facebook",
    label: "Facebook",
    img: facebook,
  },
];

class Landing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      email: "",
      user_status: "",
      open: true,
      inccorect_pass: false,
      account_message: null,
      error: " ",
      signup: false,
      success: false,
      loading: false,
      ForgotPass: false,
      showPassword: false, // New state to manage password visibility
    };
  }

  keypress(event) {
    if (event.keyCode === 13 && this.state.signup === false) {
      this.forgot();
    }
    if (event.keyCode === 27) {
      this.setState({ signup: false });
    }
  }

  login() {
    const username = this.state.username;
    const password = this.state.password;
    if ([username, password].includes("")) {
      this.setState({ error: "Please Enter All Information" });
    } else if (username.length < 6) {
      this.setState({ error: "Username must be 6 characters" });
    } else if (password.length > 0 && password.length <= 6) {
      this.setState({ error: "Password must be longer than 6 characters" });
    } else {
      const body = {
        username,
        password,
      };

      axios
        .post("/auth/login", body)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ success: true, inccorect_pass: false });
          }
        })
        .catch((err) => {
          const res = err.response;
          if (res.data.status === "Incorrect username or password.") {
            this.setState({
              error: "Password Incorrect",
              success: false,
              inccorect_pass: true,
            });
          }
        });
    }
  }

  handlePasswordVisibilityChange = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  componentDidMount() {
    document.addEventListener("keydown", (e) => this.keypress(e), false);
    this.setState({ open: true });
  }

  clearCookies() {
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }

  render() {
    if (this.state.success) {
      axios
        .get(`/api/user/${this.state.username}`)
        .then((result) => {
          const resData = result.data.status;
          this.setState({
            email: resData["email"],
            user_status: resData["user_status"],
            account_message: resData["account_message"],
          });
        })
        .catch((err) => {});
    }
    if (this.state.user_status !== "") {
      const re = {
        pathname: "/home",
        state: {
          username: this.state.username,
          email: this.state.email,
          user_status: this.state.user_status,
          account_message: this.state.account_message,
        },
      };
      return <Redirect to={re} />;
    }

    return (
      <div class={styles.landing}>
        {window.innerWidth >= 768 && (
          <div class={styles.left}>
            <img src={logo_large} alt="logo" style={{ height: "100vh" }} />
          </div>
        )}
        {window.innerWidth >= 768 && (
          <div class={styles.right}>
            <div class={styles.content}>
              <div class={styles.title}>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    display: "inline",
                    height: "40px",
                    verticalAlign: "middle",
                  }}
                />
                <div class={styles.titleText}>
                  NutraData™
                  <p class={styles.para}>
                    Your go-to website for tracking supplements
                  </p>
                </div>
              </div>
              {fields.map(({ title, label, type }) => (
                <div class={styles.input} key={title}>
                  <TextField
                    value={this.state[title]}
                    onChange={(e) => this.setState({ [title]: e.target.value })}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") this.login();
                    }}
                    id="standard"
                    label={label}
                    type={
                      title === "password"
                        ? this.state.showPassword
                          ? "text"
                          : "password"
                        : type
                    } // Toggle password visibility
                    fullWidth
                  />
                </div>
              ))}
              <div class={styles.showPassword}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.showPassword}
                      onChange={this.handlePasswordVisibilityChange}
                    />
                  }
                  label="Show Password"
                />
              </div>
              <div class={styles.error}>{this.state.error}</div>
              <div class={styles.login} onClick={() => this.login()}>
                Log in
              </div>
              <div
                class={styles.signup}
                onClick={() => this.setState({ signup: true, error: "" })}
              >
                Sign up
              </div>
              <div id={styles.forgot}>
                <a href="/login/reset">Forgot password/username?</a>
              </div>
            </div>
          </div>
        )}
        {window.innerWidth <= 768 && (
          <div class={styles.rightMobile}>
            {/* Mobile rendering */}
            <div class={styles.content}>
              <div class={styles.title}>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    display: "inline",
                    height: "40px",
                    verticalAlign: "middle",
                  }}
                />
                <div class={styles.titleText}>
                  NutraData™
                  <p class={styles.para}>
                    Your go-to website for tracking supplements
                  </p>
                </div>
              </div>
              {fields.map(({ title, label, type }) => (
                <div class={styles.input} key={title}>
                  <TextField
                    value={this.state[title]}
                    onChange={(e) => this.setState({ [title]: e.target.value })}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") this.login();
                    }}
                    id="standard"
                    label={label}
                    type={
                      title === "password"
                        ? this.state.showPassword
                          ? "text"
                          : "password"
                        : type
                    } // Toggle password visibility
                    fullWidth
                  />
                </div>
              ))}
              {/* <div class={styles.input} key="password">
    						<TextField
       							value={this.state.password}
        						onChange={(e) => this.setState({ password: e.target.value })}
        						onKeyDown={(e) => { if (e.key === 'Enter') this.login(); }}
        						id='standard'
        						label='Password'
        						type={this.state.showPassword ? 'text' : 'password'}
        						fullWidth
							/>
						</div> */}
              <div class={styles.showPassword}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.showPassword}
                      onChange={this.handlePasswordVisibilityChange}
                    />
                  }
                  label="Show Password"
                />
              </div>
              <div class={styles.error}>{this.state.error}</div>
              <div class={styles.login} onClick={() => this.login()}>
                Log in
              </div>
              <div
                class={styles.signup}
                onClick={() => this.setState({ signup: true, error: "" })}
              >
                Sign up
              </div>
              <div id={styles.forgot}>
                <a href="/login/reset">Forgot password/username?</a>
              </div>
            </div>
          </div>
        )}
        {this.state.signup ? <Signup open={this.state.signup} /> : null}
      </div>
    );
  }
}

export default Landing;
