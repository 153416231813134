import React from "react";
import { Redirect } from "react-router-dom";
import { TextField } from "@material-ui/core";
import styles from "./Signup.module.css";
import axios from "axios";
import { Checkbox } from "@mui/material";

const fields = [
  {
    title: "username",
    label: "Username",
    type: "",
  },
  {
    title: "email",
    label: "E-mail",
    type: "",
  },
  {
    title: "password",
    label: "Password",
    type: "password",
  },
  {
    title: "confirm",
    label: "Confirm Password",
    type: "password",
  },
];

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      confirm: "",
      error: "No error",
      success: false,
      step: 0,
      realCode: "XXXXXX",
      checkbox: false,
      showPassword: false, // Added state to toggle password visibility
    };
  }

  handleLoginClick = () => {
    return <Redirect to="/login" />;
  };

  join() {
    const { username, email, password, confirm } = this.state;
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ([username, email, password, confirm].includes("")) {
      this.setState({ error: "Please complete all fields" });
    } else if (password.length > 0 && password.length <= 6) {
      this.setState({ error: "Password must be longer than 6 characters" });
    } else if (password !== confirm) {
      this.setState({ error: "Passwords Don't Match" });
      this.setState({ password: "" });
      this.setState({ confirm: "" });
    } else if (!regex.test(String(email).toLowerCase())) {
      this.setState({ error: "Please Enter A Valid E-mail" });
    } else {
      const body = {
        username: username,
        password: password,
        display_name: "Display",
        email: email,
        beta_tester: true,
      };

      axios
        .post("/auth/register", body)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ error: "No error" });
            this.setState({ step: 1 });
          }
        })
        .catch((err) => {
          if (err === "Error: Request failed with status code 500") {
            this.setState({ error: "That email is already taken" });
            this.setState({ email: "" });
          } else {
            if (username.length < 6) {
              this.setState({
                error: "Username must be longer than 6 characters",
              });
              this.setState({ username: "" });
            } else {
              this.setState({ error: "Username is already taken" });
              this.setState({ username: "" });
            }
          }
        });
    }
  }

  keypress(event) {
    if (event.keyCode === 13 && this.props.open) this.join();
  }

  componentDidMount() {
    document.addEventListener("keydown", (e) => this.keypress(e), false);
  }

  render() {
    const err = this.state.error;

    if (this.state.success) {
      return <Redirect to="/home" />;
    }

    return (
      <div className={styles.backdrop}>
        <div className={styles.signup}>
          <div className={styles.content}>
            <div className={styles.title}>Sign Up</div>
            {!this.state.step ? (
              <div>
                <div>
                  {fields.map(({ title, label, type }) => {
                    return (
                      <div className={styles.input} key={title}>
                        <TextField
                          value={this.state[title]}
                          onChange={(e) =>
                            this.setState({ [title]: e.target.value })
                          }
                          id="standard"
                          label={label}
                          type={
                            title === "password" || title === "confirm"
                              ? this.state.showPassword
                                ? "text"
                                : "password"
                              : type
                          }
                          width={1}
                          fullWidth="true"
                        />
                      </div>
                    );
                  })}
                </div>

                {/* Error message */}
                <div
                  className={
                    err !== "No error" ? styles.error : styles.noerr
                  }
                >
                  {err}
                </div>

                {/* Join button */}
                <div
                  className={styles.join}
                  onClick={
                    this.state.checkbox
                      ? () => this.join()
                      : () =>
                          alert(
                            "You must check that you have agreed to our terms of service."
                          )
                  }
                >
                  Join NutraData™
                </div>

                {/* Terms of Service checkbox */}
                <div className={styles.terms}>
                  <input
                    type="checkbox"
                    onChange={() => {
                      this.setState(
                        { checkbox: !this.state.checkbox },
                        () => {
                          console.log(this.state.checkbox);
                        }
                      );
                    }}
                  />
                  By checking this box, you are agreeing to{" "}
                  <a href="/disclaimer">Our Terms of Service</a>.
                </div>

                {/* Show Password checkbox */}
                <div className={styles.showPasswordContainer}>
                  <Checkbox
                    onChange={() =>
                      this.setState({
                        showPassword: !this.state.showPassword,
                      })
                    }
                  />
                  <span>Show Password</span>
                </div>
              </div>
            ) : (
              <div>
                <div className={styles.input}>
                  Please verify your account with the confirmation sent to your
                  email
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
